import { Route, Routes } from "react-router-dom";
// import ProtectRoutes from "./ProtectedRoutes";

import Auth from "./RoleAuth";
import {
  AuthPage,
  BookingsPage,
  CampaignsPage,
  Contact,
  DashboardSettings,
  FeedsPage,
  Home,
  OrganizerHome,
  PageManager,
  PageOpener,
  ReviewsPage,
  TeamsPage,
  TicketsPage,
  TransactionsPage,
} from "../pages";
import { OrganizerRoutes } from "./ProtectedRoutes";

const AllRoutes = () => {
  return (
    <Routes>
      {/* <Route element={<ProtectRoutes />}>
        <Route element={<Subscription />} path="/subscription"></Route>
      </Route> */}
      {/* <Route element={<AuthPage />} path="/login"></Route>
      <Route element={<AuthPage />} path="/"></Route> */}
      <Route element={<Home />} path="/"></Route>
      {/* <Route element={<PageOpener />} path="/accounts"></Route>
      <Route element={<PageManager />} path="/manager"></Route>
      <Route element={<Contact />} path="/contact"></Route> */}

      {/* <Route element={<OrganizerRoutes />} path="/organizer">
        <Route element={<OrganizerHome />} index></Route>
        <Route element={<OrganizerHome />} path="dashboard"></Route>
        <Route element={<TicketsPage />} path="tickets"></Route>
        <Route element={<TransactionsPage />} path="transactions"></Route>
        <Route element={<TeamsPage />} path="teams"></Route>
        <Route element={<DashboardSettings />} path="settings"></Route>
        <Route element={<BookingsPage />} path="bookings"></Route>
        <Route element={<CampaignsPage />} path="campaigns"></Route>
        <Route element={<ReviewsPage />} path="reviews"></Route>
        <Route element={<FeedsPage />} path="feeds"></Route>
      </Route> */}
    </Routes>
  );
};

export default AllRoutes;
