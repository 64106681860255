import { ThinStar } from "@smastrom/react-rating";

const COLORS = {
  primary: "#f42d2d"
}
const customStyles = {
  itemShapes: ThinStar,
  activeBoxColor: ["#e7040f", "#ff6300", "#ffde37", "#61bb00", "#19a974"],
  inactiveBoxColor: "#C7C7C7",
  inactiveFillColor: "white",
  activeFillColor: "white",
};
export { COLORS, customStyles };
