const getStartedBtn = () => {
    const ahref = document.createElement("a");
    ahref.href = "https://forms.gle/yqWAjvvZazheooyDA";
    ahref.target = "_blank";
    ahref.click();
};


export {
    getStartedBtn
}