import { Marker, Popup } from "react-leaflet";
import { useState, useRef, useMemo, useCallback } from "react";
import L from "leaflet";
import { locationPin } from "constants/assets";

const markerIcon = L.icon({
  iconUrl: locationPin,
  iconSize: [45, 45],
});

const DraggableMarker = ({ center, changeLocation }: any) => {
  const [draggable, setDraggable] = useState(false);
  const [position, setPosition] = useState(center);
  const markerRef = useRef(null);
  const eventHandlers = useMemo(
    () => ({
      dragend() {
        const marker: any = markerRef.current;
        if (marker != null) {
          setPosition(marker.getLatLng());
          changeLocation([marker.getLatLng().lat, marker.getLatLng().lng]);
        }
      },
    }),
    []
  );
  const toggleDraggable = useCallback(() => {
    setDraggable((d) => !d);
  }, []);

  return (
    <Marker
      draggable={draggable}
      eventHandlers={eventHandlers}
      position={position}
      ref={markerRef}
      icon={markerIcon}
    >
      <Popup minWidth={90}>
        <span onClick={toggleDraggable}>
          {draggable
            ? "Marker is draggable"
            : "Click here to change your location"}
        </span>
      </Popup>
    </Marker>
  );
};

export { DraggableMarker };
