import { Badge } from "@/components/ui/badge";
import { Button } from "@/components/ui/button";
import {
  CardHeader,
  CardTitle,
  CardDescription,
  CardContent,
  Card,
} from "@/components/ui/card";
import { Label } from "@/components/ui/label";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { Separator } from "@/components/ui/separator";
import { Calendar } from "lucide-react";
import React from "react";

type Props = {};

const BookingView = (props: Props) => {
  return (
    <div>
      <Card className="w-full p-0 border-0 shadow-none">
        <CardHeader>
          <CardTitle>View Booking</CardTitle>
          <CardDescription>Shows detailed info of booking</CardDescription>
        </CardHeader>
        <CardContent>
          <div className="bg-white p-5 gap-3 rounded-lg border">
            <div className="flex justify-between items-center">
              <h5 className="font-medium text-md">BookingID: #BOY863946</h5>
              <span className="text-sm text-muted-foreground">
                3rd aug. 2024
              </span>
            </div>
            <div className="flex justify-start items-center gap-1">
              <Calendar size={15} />
              <p className="text-sm">Booking Date: 7th september 2034</p>
            </div>
            <Badge className="text-xs" variant="secondary">
              Fulfilled
            </Badge>
            <Separator className="my-3" />
            <div>
              <span className="text-sm text-muted-foreground">Message</span>
              <p className="text-sm">
                Lorem, ipsum dolor sit amet consectetur adipisicing elit.
                Obcaecati dolores incidunt ipsa blanditiis doloribus id nostrum
                aperiam maxime recusandae non?
              </p>
            </div>
          </div>
          <div className="flex justify-between items-center mt-3">
            <div className="flex flex-col space-y-1.5">
              <Label
                htmlFor="maxtickets"
                className="text-sm text-muted-foreground"
              >
                Status
              </Label>
              <Select>
                <SelectTrigger id="maxtickets" className="w-[200px]">
                  <SelectValue placeholder="Select" />
                </SelectTrigger>
                <SelectContent position="popper">
                  {["live", "ended", "on hold"].map((status) => (
                    <SelectItem
                      className="capitalize"
                      value={`${status}`}
                      key={status}
                    >
                      {status}
                    </SelectItem>
                  ))}
                </SelectContent>
              </Select>
            </div>
            <Button className="bg-black">Submit</Button>
          </div>
        </CardContent>
      </Card>
    </div>
  );
};

export default BookingView;
