import { VolumeLoader } from "react-loaders-kit";
const Loader = ({ loading }: any) => {
  const loaderProps = {
    loading,
    size: 30,
    duration: 1,
    colors: ["#000", "#fe0036"],
  };
  return (
    <div
      className="loader-main"
      style={{ display: loading ? "block" : "none" }}
    >
      <VolumeLoader {...loaderProps} />
    </div>
  );
};

export default Loader;