import { Button } from "../../@/components/ui/button";
import { ChevronRight } from "lucide-react";
import React from "react";
import PlayStore from "../../assets/img/main/playstore.svg";
import AppStore from "../../assets/img/main/apple.svg";
import { logo } from "../../constants/assets";
import { Link } from "react-router-dom";
import { getStartedBtn } from "services/misc";
type Props = {};

const LandingHeader = (props: Props) => {
  const links = [
    { name: "appstore", img: AppStore },
    { name: "playstore", img: PlayStore },
  ];
  return (
    <div className="w-full px-4 h-auto py-5 border-b-[1px] border-b-gray-100">
      <div className="mx-auto xl:max-w-screen-xl md:max-w-screen-sm">
        <div className="flex justify-between items-center">
          <div className="flex justify-start items-center gap-4">
            <div>
              <img src={logo} alt="homelogo" className="w-auto h-8 lg:h-12" />
            </div>
            {/* <div>
              <ul className="font-regular flex flex-col p-4 md:p-0 mt-4 border border-gray-100 rounded-lg bg-gray-50 md:flex-row md:space-x-8 rtl:space-x-reverse md:mt-0 md:border-0 md:bg-white dark:bg-gray-800 md:dark:bg-gray-900 dark:border-gray-700">
                <li>
                  <Link
                    to="/home"
                    className="block py-2 px-3 font-medium text-white bg-blue-700 rounded md:bg-transparent md:text-black ho md:p-0"
                    aria-current="page"
                  >
                    Home
                  </Link>
                </li>
                <li>
                  <Link
                    to="/pricing"
                    className="block py-2 px-3 text-gray-400 rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-blue-700 md:p-0"
                  >
                    Pricing
                  </Link>
                </li>
                <li>
                  <Link
                    to="/contact"
                    className="block py-2 px-3 text-gray-400 rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-blue-700 md:p-0"
                  >
                    Contact
                  </Link>
                </li>
              </ul>
            </div> */}
          </div>
          <div className="flex justify-end items-center gap-3">
            {/* {links.map((link) => (
              <div
                className="p-1 rounded-full border border-gray-300"
                key={link.name}
              >
                <img src={link.img} alt="" className="w-7 h-7" />
              </div>
            ))} */}
            <Button className="bg-black" onClick={getStartedBtn}>
              {/* Login / Register */}
              Get Started
              <ChevronRight className="ml-2" size={20} />
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LandingHeader;
