import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    laduser: null,
    loggedIn: false,
    ladTokens: null,
}

const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        assignAdmin: (state, data: any) => {
            state.laduser = data.payload;
            state.loggedIn = true;
        },
        unAssignAdmin: (state) => {
            state.laduser = null;
            state.loggedIn = false;
            state.ladTokens = null;
        },
        assignLadTokens: (state, data) => {
            state.ladTokens = data.payload;
        },
        updateUser: (state, data: any) => {
            state.laduser = data.payload;
        },

    }
})


export const { assignAdmin, unAssignAdmin, updateUser, assignLadTokens } = authSlice.actions;
export default authSlice.reducer;