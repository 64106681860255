import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";
import { Input } from "@/components/ui/input";
import { format } from "date-fns";
import {
  SelectTrigger,
  SelectValue,
  Select as MainSelect,
  SelectContent,
  SelectItem,
  SelectGroup,
} from "@/components/ui/select";
import Select from "react-select";
import {
  PopoverTrigger,
  PopoverContent,
  Popover,
} from "@/components/ui/popover";
import { countries } from "country-list-json";
import {
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";
import { cn } from "@/lib/utils";
import { CalendarIcon, Image } from "lucide-react";
import { Textarea } from "@/components/ui/textarea";
import { Button } from "@/components/ui/button";
import { authImg } from "constants/assets";
import { Calendar } from "@/components/ui/calendar";
import SettingsMap from "pages/dashboard/dashboardsettings/components/components/SettingsMap";
import { useState } from "react";
import { Label } from "@/components/ui/label";
import { Card } from "flowbite-react";

type AEProps = {
  viewType: string;
};

const AddEditEvent = ({ viewType }: AEProps) => {
  const [date, setDate] = useState<Date>();
  const [location, setLocation] = useState<any>([
    5.60003365639047, -0.18919420975159973,
  ]);
  const options = [
    { value: "chocolate", label: "Chocolate" },
    { value: "strawberry", label: "Strawberry" },
    { value: "vanilla", label: "Vanilla" },
  ];

  const multiSelecClasses = {
    control: () => "!border-slate-200 !py-[.4%] !rounded-md ",
    placeholder: () => "!text-sm !text-muted-foreground",
    multiValueLabel: () => "bg-slate-100",
    multiValueRemove: () => "bg-slate-200",
  };
  return (
    <Card
      x-chunk="dashboard-05-chunk-3"
      className={`${viewType === "add" ? "border-none shadow-none" : ""}`}
    >
      <CardHeader className="p-0">
        <CardTitle>
          {viewType === "add" ? "Add Event" : "Event Settings"}
        </CardTitle>
        <CardDescription>
          {viewType === "add"
            ? "Fill the following fields. Click submit to save event."
            : "Make changes to your event here."}
        </CardDescription>
      </CardHeader>
      <CardContent
        className={`p-0 ${
          viewType === "add" ? "max-h-[75vh] overflow-y-scroll pe-2" : ""
        }`}
      >
        <Tabs defaultValue="basic">
          <TabsList className="grid w-full grid-cols-3 mb-7">
            <TabsTrigger value="basic">Basic Information</TabsTrigger>
            <TabsTrigger value="photos">Photos & Socials</TabsTrigger>
            <TabsTrigger value="venue">Venue </TabsTrigger>
          </TabsList>
          <TabsContent value="basic">
            <div>
              <div className="mb-4">
                <div className="flex flex-col space-y-1.5">
                  <Label htmlFor="tickets">Name of event</Label>
                  <Input id="tickets" type="text" placeholder="Early Bird" />
                </div>
              </div>
              <div className="grid gap-4 mb-4 sm:grid-cols-2">
                <div className="flex flex-col space-y-1.5">
                  <Label htmlFor="tickets">Event Category</Label>
                  <MainSelect name="origin">
                    <SelectTrigger
                      className="w-full focus:ring-0 mt-1"
                      id="origin"
                    >
                      <SelectValue
                        className="focus:ring-0 font-normal"
                        placeholder="Select"
                      />
                    </SelectTrigger>
                    <SelectContent className="h-36">
                      <SelectGroup>
                        <SelectItem value="party">Party</SelectItem>
                        <SelectItem value="dance">dance</SelectItem>
                        <SelectItem value="wedding">wedding</SelectItem>
                        <SelectItem value="concert">concert</SelectItem>
                      </SelectGroup>
                    </SelectContent>
                  </MainSelect>
                </div>
                <div className="flex flex-col space-y-1.5">
                  <Label htmlFor="maxtickets">Privacy</Label>
                  <MainSelect name="origin">
                    <SelectTrigger
                      className="w-full focus:ring-0 mt-1"
                      id="origin"
                    >
                      <SelectValue
                        className="focus:ring-0 font-normal"
                        placeholder="Select"
                      />
                    </SelectTrigger>
                    <SelectContent className="h-36">
                      <SelectGroup>
                        <SelectItem value="public">Public</SelectItem>
                        <SelectItem value="private">Private</SelectItem>
                      </SelectGroup>
                    </SelectContent>
                  </MainSelect>
                </div>
              </div>
              <div className="grid gap-4 mb-4 sm:grid-cols-2">
                <div className="flex flex-col space-y-1.5">
                  <Label htmlFor="startselling">Start Date</Label>
                  <Popover>
                    <PopoverTrigger asChild>
                      <Button
                        variant={"outline"}
                        className={cn(
                          "w-full justify-start text-left font-normal",
                          !date && "text-muted-foreground"
                        )}
                      >
                        <CalendarIcon className="mr-2 h-4 w-4" />
                        {date ? format(date, "PPP") : <span>Pick a date</span>}
                      </Button>
                    </PopoverTrigger>
                    <PopoverContent className="w-auto p-0" align="start">
                      <Calendar
                        mode="single"
                        selected={date}
                        onSelect={setDate}
                        initialFocus
                      />
                    </PopoverContent>
                  </Popover>
                </div>
                <div className="flex flex-col space-y-1.5">
                  <Label htmlFor="startselling">End Date</Label>
                  <Popover>
                    <PopoverTrigger asChild>
                      <Button
                        variant={"outline"}
                        className={cn(
                          "w-full justify-start text-left font-normal",
                          !date && "text-muted-foreground"
                        )}
                      >
                        <CalendarIcon className="mr-2 h-4 w-4" />
                        {date ? format(date, "PPP") : <span>Pick a date</span>}
                      </Button>
                    </PopoverTrigger>
                    <PopoverContent className="w-auto p-0" align="start">
                      <Calendar
                        mode="single"
                        selected={date}
                        onSelect={setDate}
                        initialFocus
                      />
                    </PopoverContent>
                  </Popover>
                </div>
              </div>
              <div className="mb-4">
                <div className="flex flex-col space-y-1.5">
                  <Label htmlFor="maxtickets">Event Tags</Label>
                  <Select
                    closeMenuOnSelect={false}
                    isMulti
                    classNames={{ ...multiSelecClasses }}
                    options={options}
                  />
                </div>
              </div>
              <div className="mb-4">
                <div className="flex flex-col space-y-1.5">
                  <Label htmlFor="tickets">Media</Label>
                  <Input
                    id="tickets"
                    type="text"
                    placeholder="Youtube link video eg. https://ytbe.com"
                  />
                </div>
              </div>
              <div className="mb-4">
                <div className="flex flex-col space-y-1.5">
                  <Label htmlFor="maxtickets">Description</Label>
                  <Textarea
                    placeholder="Type your message here."
                    className="resize-none"
                  />
                </div>
              </div>
              <div className="text-center">
                <Button className="bg-black">Submit</Button>
              </div>
            </div>
          </TabsContent>
          <TabsContent value="photos">
            <div>
              <div className="flex justify-between items-start">
                <div>
                  <Label htmlFor="maxtickets">Cover Photo</Label>
                  <p className="text-muted-foreground text-sm mb-3">
                    Will appear on top of the event page.
                  </p>
                </div>
                <Button className="bg-black">
                  <Image /> {""} <span className="ms-2">Upload cover</span>
                </Button>
              </div>
              <div className="w-64 h-40 rounded-lg mb-7">
                <img
                  src={authImg}
                  alt=""
                  className="w-full h-full object-cover rounded-lg"
                />
              </div>
              <div className="flex justify-between items-start">
                <div>
                  <Label htmlFor="maxtickets">Event Poster</Label>
                  <p className="text-muted-foreground text-sm mb-3">
                    Will appear on the event page
                  </p>
                </div>
                <Button className="bg-black">
                  <Image /> {""} <span className="ms-2">Upload Poster</span>
                </Button>
              </div>
              <div className="grid grid-cols-5 gap-4">
                <div className="w-64 h-40 rounded-lg mb-7">
                  <img
                    src={authImg}
                    alt=""
                    className="w-full h-full object-cover rounded-lg"
                  />
                </div>
              </div>
              <div className="grid grid-cols-2 gap-4 mt-10">
                <div className="space-y-1">
                  <Label htmlFor="facebook">Facebook Link</Label>
                  <Input
                    id="facebook"
                    className="focus-visible:outline-0 focus:outline-none text-md focus-visible:ring-0 text-sm font-light resize-none"
                    placeholder="https://web.facebook.com/outfunapp"
                    name="facebook"
                  />
                </div>
                <div className="space-y-1">
                  <Label htmlFor="instagram">Instagram Link</Label>
                  <Input
                    id="instagram"
                    className="focus-visible:outline-0 focus:outline-none text-md focus-visible:ring-0 text-sm font-light resize-none"
                    placeholder="https://instagram.com/outfunapp"
                    name="instagram"
                  />
                </div>
                <div className="space-y-1">
                  <Label htmlFor="twitter">Twitter Link</Label>
                  <Input
                    id="twitter"
                    className="focus-visible:outline-0 focus:outline-none text-md focus-visible:ring-0 text-sm font-light resize-none"
                    placeholder="https://twitter.com/outfunapp"
                    name="twitter"
                  />
                </div>
                <div className="space-y-1">
                  <Label htmlFor="youtube">Youtube Link</Label>
                  <Input
                    id="youtube"
                    className="focus-visible:outline-0 focus:outline-none text-md focus-visible:ring-0 text-sm font-light resize-none"
                    placeholder="https://youtube.com/outfunapp"
                    name="youtube"
                  />
                </div>
                <div className="space-y-1">
                  <Label htmlFor="linkedin">LinkedIn Link</Label>
                  <Input
                    id="linkedin"
                    className="focus-visible:outline-0 focus:outline-none text-md focus-visible:ring-0 text-sm font-light resize-none"
                    placeholder="https://linkedin.com/outfunapp"
                    name="linkedin"
                  />
                </div>
              </div>
              <div className="text-center mt-5">
                <Button className="bg-black">Submit</Button>
              </div>
            </div>
          </TabsContent>
          <TabsContent value="venue">
            <div className="mb-4">
              <div className="space-y-1">
                <Label htmlFor="website">Venue Name</Label>
                <Input
                  id="website"
                  className="focus-visible:outline-0 focus:outline-none text-md focus-visible:ring-0 text-sm font-light resize-none"
                  placeholder="Jams Arena"
                  name="website"
                />
              </div>
            </div>
            <div className="space-y-1 mb-4">
              <Label htmlFor="address">Street address</Label>
              <Input
                id="address"
                className="focus-visible:outline-0 focus:outline-none text-md focus-visible:ring-0 text-sm font-light resize-none"
                placeholder="Old Road Street"
                name="street_add"
              />
            </div>
            <div className="grid grid-cols-2 gap-4 mb-4">
              <div className="space-y-1">
                <Label htmlFor="city">City</Label>
                <Input
                  id="city"
                  className="focus-visible:outline-0 focus:outline-none text-md focus-visible:ring-0 text-sm font-light resize-none"
                  placeholder="Accra"
                  name="city"
                />
              </div>
              <div>
                <Label htmlFor="origin">Country</Label>
                <MainSelect name="origin">
                  <SelectTrigger
                    className="w-full focus:ring-0 mt-1"
                    id="origin"
                  >
                    <SelectValue
                      className="focus:ring-0 font-normal"
                      placeholder="Select country"
                    />
                  </SelectTrigger>
                  <SelectContent className="h-36">
                    <SelectGroup>
                      {countries.map((country) => (
                        <SelectItem value={country.name} key={country.name}>
                          {country.name} {country.flag}
                        </SelectItem>
                      ))}
                    </SelectGroup>
                  </SelectContent>
                </MainSelect>
              </div>
            </div>

            <div>
              <Label htmlFor="maxtickets">Venue Location</Label>
              <div className="w-full rounded-lg h-80 mt-3">
                {/* {location !== undefined && ( */}
                <SettingsMap location={location} setFunc={setLocation} />
                {/* )} */}
              </div>
            </div>
            <div className="text-center mt-5">
              <Button className="bg-black">Submit</Button>
            </div>
          </TabsContent>
        </Tabs>
      </CardContent>
    </Card>
  );
};

export default AddEditEvent;
